import type { IComponentVideo } from '~/contentful/types';
import { getNoCookieYouTubeUrl } from './video.utils';

export const VideoComponent = ({ fields }: IComponentVideo) => {
  const { url, title } = fields;
  const noCookieUrl = getNoCookieYouTubeUrl(url);

  if (!noCookieUrl) {
    return null;
  }

  return (
    <iframe className="aspect-video w-full" src={noCookieUrl} allow="fullscreen" title={title} />
  );
};
