import cn from 'clsx';
import { Typography } from '@aph/ui/components/typography/typography';
import type { ContentCardItem } from './content-card';
import { ContentCard } from './content-card';
import { getContentCardColor } from './get-content-card-colors';

type ContentCardListingProps = {
  title?: string;
  items: Array<ContentCardItem> | undefined;
  onItemClick?: (item: ContentCardItem) => void;
};

export const ContentCardListing = ({ title, items, onItemClick }: ContentCardListingProps) => {
  if ((items ?? []).length <= 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {title && (
        <Typography typography="headingMedium" color="text-default" asChild>
          <h2>{title}</h2>
        </Typography>
      )}

      <div className="grid w-full grid-cols-2 gap-x-1 gap-y-2 sm:grid-cols-3 sm:gap-x-2 lg:grid-cols-4">
        {items
          ?.filter((x) => x.image)
          .slice(0, 4)
          .map((item, index) => {
            const position = index + 1;
            return (
              <div
                key={item.href}
                className={cn({
                  'max-sm:hidden': position > 2,
                  'sm:max-lg:hidden': position > 3,
                })}
              >
                <ContentCard item={item} color={getContentCardColor(index)} onClick={onItemClick} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
