import type { HTMLAttributes } from 'react';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { IComponentAccordionList } from '~/contentful/types';
import { Accordion } from '../accordion/accordion.component';

const AccordionListBase = ({
  fields,
  className,
}: IComponentAccordionList & { className?: HTMLAttributes<HTMLUListElement>['className'] }) => {
  const { accordionItems, title } = fields;
  return (
    <div className="flex flex-col gap-1">
      {title && (
        <Typography typography="headingMedium" asChild>
          <h3>{title}</h3>
        </Typography>
      )}
      <ul
        className={cn(
          'grid list-none grid-cols-1 gap-x-14 sm:grid-cols-1 sm:justify-between',
          className,
        )}
      >
        {accordionItems.map((accordionItem) => {
          return (
            <li key={accordionItem.sys.id} className="sm:max-w-lg">
              <Accordion {...accordionItem} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const AccordionList = (props: IComponentAccordionList) => <AccordionListBase {...props} />;

export const AccordionListTwoColumns = (props: IComponentAccordionList) => (
  <AccordionListBase {...props} className="sm:grid-cols-2" />
);
