import { createQuery } from 'react-query-kit';
import { ArticlesApiClient } from '~/articles/api/ArticlesApiClient';

export const useGetArticlesByCodesQuery = createQuery({
  queryKey: ['get-articles-by-codes'],
  fetcher: (variables: { codes: string[] }, { meta }) => {
    const articlesApiClient = new ArticlesApiClient({ headers: meta?.headers });
    return articlesApiClient.getArticlesByCodes(variables.codes);
  },
  staleTime: Infinity,
});
