import { Typography } from '@aph/ui/components/typography/typography';
import type { IComponentSection } from '~/contentful/types';
import { useContentRenderer } from '~/contexts/content-renderer/content-renderer.provider';

export const Section = ({ fields }: IComponentSection) => {
  const { ContentRenderer, mappings } = useContentRenderer();
  const { layout, title, items } = fields;
  let component: JSX.Element | null;

  switch (layout) {
    case 'Horizontal':
      component = (
        <div className="flex flex-col gap-4 sm:grid sm:grid-cols-2">
          <ContentRenderer content={items} mappings={mappings} />
        </div>
      );
      break;
    case 'Vertical':
      component = (
        <div className="flex flex-col gap-4">
          <ContentRenderer content={items} mappings={mappings} />
        </div>
      );
      break;
    default:
      component = (
        <div>
          <ContentRenderer content={items} mappings={mappings} />
        </div>
      );
  }

  return (
    <section className="flex flex-col gap-4">
      {title && (
        <Typography typography="headingLarge" color="text-brand" asChild>
          <h2>{title}</h2>
        </Typography>
      )}
      {component}
    </section>
  );
};
