/* eslint-disable prefer-destructuring */

/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { cn } from '@aph/ui/tailwind/cn';
import type { EventFor } from '@aph/utilities/event-for';
import type { Omit } from '@aph/utilities/ts-omit';
import { ButtonCircle, type ButtonCircleProps } from '../button-circle/button-circle';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  options?: CarouselOptions;
  plugins?: CarouselPlugin;
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(({ options = {}, setApi, plugins = [], className, children, ...props }, forwardedRef) => {
  const [carouselRef, api] = useEmblaCarousel(
    {
      axis: 'x',
      slidesToScroll: 'auto',
      watchDrag: (api) => api.internalEngine().scrollSnaps.length > 1,
      loop: false,
      ...options,
    },
    [WheelGesturesPlugin({ forceWheelAxis: 'x' }), ...plugins],
  );
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const onSelect = useCallback((api: CarouselApi) => {
    if (api) {
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }
  }, []);

  const scrollPrev = useCallback(() => {
    api?.scrollPrev();
  }, [api]);

  const scrollNext = useCallback(() => {
    api?.scrollNext();
  }, [api]);

  const handleKeyDown = useCallback(
    (event: EventFor<'div', 'onKeyDown'>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        scrollPrev();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        scrollNext();
      }
    },
    [scrollPrev, scrollNext],
  );

  useEffect(() => {
    if (!api || !setApi) {
      return;
    }

    setApi(api);
  }, [api, setApi]);

  useEffect(() => {
    if (api) {
      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);

  const contextValue = useMemo(
    () => ({
      carouselRef,
      api,
      options,
      scrollPrev,
      scrollNext,
      canScrollPrev,
      canScrollNext,
    }),
    [carouselRef, api, options, scrollPrev, scrollNext, canScrollPrev, canScrollNext],
  );

  return (
    <CarouselContext.Provider value={contextValue}>
      <div
        ref={forwardedRef}
        onKeyDownCapture={handleKeyDown}
        className={cn('overflow-hidden', className)}
        role="group"
        aria-roledescription="karusell"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
});
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, forwardedRef) => {
    const { carouselRef } = useCarousel();

    return (
      <div ref={carouselRef} className="overflow-hidden">
        <div ref={forwardedRef} className={cn('flex', className)} {...props} />
      </div>
    );
  },
);
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="sida"
        className={cn(
          'min-w-0 shrink-0 grow-0 basis-full',

          className,
        )}
        {...props}
      />
    );
  },
);
CarouselItem.displayName = 'CarouselItem';

type CarouselButtonProps = Omit<ButtonCircleProps, 'icon' | 'text'> & {
  icon?: ButtonCircleProps['icon'];
};

const CarouselPrevious = React.forwardRef<HTMLButtonElement, CarouselButtonProps>(
  (
    { className, variant = 'white', size = 'medium', icon = 'ArrowheadLeft', ...props },
    forwardedRef,
  ) => {
    const { scrollPrev, canScrollPrev } = useCarousel();

    return (
      <ButtonCircle
        ref={forwardedRef}
        variant={variant}
        size={size}
        className={cn(className)}
        aria-disabled={!canScrollPrev}
        onClick={scrollPrev}
        icon={icon}
        aria-label="Föregående sida"
        {...props}
      />
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<HTMLButtonElement, CarouselButtonProps>(
  (
    { className, variant = 'white', size = 'medium', icon = 'ArrowheadRight', ...props },
    forwardedRef,
  ) => {
    const { scrollNext, canScrollNext } = useCarousel();

    return (
      <ButtonCircle
        ref={forwardedRef}
        variant={variant}
        size={size}
        className={cn(className)}
        aria-disabled={!canScrollNext}
        onClick={scrollNext}
        icon={icon}
        aria-label="Nästa sida"
        {...props}
      />
    );
  },
);
CarouselNext.displayName = 'CarouselNext';

const CarouselDotPagination = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, forwardedRef) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { api } = useCarousel();
  const scrollSnaps = useScrollSnaps();

  const scrollTo = useCallback((index: number) => api && api.scrollTo(index), [api]);

  const onSelect = useCallback(() => {
    if (api) {
      setSelectedIndex(api.selectedScrollSnap());
    }
  }, [api]);

  useEffect(() => {
    if (api) {
      api.on('select', onSelect);
      onSelect();
    }

    return () => {
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);

  return (
    <div ref={forwardedRef} className={cn('flex gap-x-1', className)} {...props}>
      {scrollSnaps.map((_, index) => (
        <button
          type="button"
          aria-label={`Visa sida ${index + 1} av ${scrollSnaps.length}`}
          aria-current={index === selectedIndex ? true : undefined}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => scrollTo(index)}
          className="bg-visual-dark aria-[current=true]:bg-action-dark relative size-1 appearance-none rounded-xl after:absolute after:inset-0 after:-m-0.5"
        />
      ))}
    </div>
  );
});
CarouselDotPagination.displayName = 'CarouselDotPagination';

const CarouselPagination = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, forwardedRef) => {
    const scrollSnaps = useScrollSnaps();

    return (
      <div
        ref={forwardedRef}
        className={cn(
          'flex flex-row items-center justify-center gap-3',
          { hidden: scrollSnaps.length < 2 },
          className,
        )}
        role="group"
        aria-label="Karusellnavigering"
        {...props}
      >
        {children}
      </div>
    );
  },
);
CarouselPagination.displayName = 'CarouselPagination';

function useScrollSnaps() {
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);
  const { api } = useCarousel();

  const onResize = useCallback(() => {
    if (api) {
      setScrollSnaps(api.scrollSnapList());
    }
  }, [api]);

  useEffect(() => {
    if (api) {
      setScrollSnaps(api.scrollSnapList());
      api.on('resize', onResize);
    }

    return () => {
      api?.off('resize', onResize);
    };
  }, [api, onResize]);

  return scrollSnaps;
}

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDotPagination,
  CarouselPagination,
};
