import { Typography } from '@aph/ui/components/typography/typography';
import type { EditorialCardIem } from './editorial-card';
import { EditorialCard, EditorialCardSkeleton } from './editorial-card';

type EditorialListingProps = {
  title?: string;
  items?: Array<EditorialCardIem & { id?: string }> | undefined;
  children?: React.ReactNode;
  onItemClick?: (item: EditorialCardIem) => void;
};

export const EditorialListing = ({
  title,
  items,
  children,
  onItemClick,
}: EditorialListingProps) => (
  <div className="flex flex-col gap-y-2">
    {title && (
      <Typography typography="headingMedium" asChild>
        <h2>{title}</h2>
      </Typography>
    )}
    <div className="grid w-full grid-cols-2 gap-x-1 gap-y-2 sm:grid-cols-4 sm:gap-x-2">
      {items
        ? items?.map((item) => (
            <EditorialCard key={item.id ?? item.href} item={item} onClick={onItemClick} />
          ))
        : children}
    </div>
  </div>
);

export const EditorialListingSkeleton = ({ items, title }: { items: number; title: string }) => (
  <EditorialListing title={title}>
    {[...Array(items).keys()].map((i) => (
      <EditorialCardSkeleton key={i} />
    ))}
  </EditorialListing>
);
