import { PageArticlePanel } from '~/articles/components/article-panel/page-article-panel';
import type { IProductPanel } from '~/contentful/types';

// NOTE: This is a temporary solution until we have a better way to get consistent id:s from eSales
// the idea is to have a mapping between the id:s we get from contentful and the title we get from eSales
const mapPanelIdToTitle = (panelId: IProductPanel['fields']['panelId']) => {
  switch (panelId) {
    case 'top-sellers':
      return 'Topplistan';
    case 'recommend-based-on-customer':
      return 'Utvalt för dig';
    case 'news-recommend-based-on-customer':
      return 'Utvalda nyheter';
    default:
      return '';
  }
};

export type ProductPanelProps = IProductPanel & { pageId: string };

export const ProductPanel = ({ fields, pageId }: ProductPanelProps) => (
  <PageArticlePanel
    pageId={pageId}
    find={(group) =>
      group.title?.localeCompare(mapPanelIdToTitle(fields.panelId), 'sv', {
        sensitivity: 'accent',
      }) === 0
    }
  />
);
