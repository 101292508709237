import { ArticleCarousel } from '~/articles/components/article-carousel/article-carousel';
import { useGetCategoryArticles } from '~/articles/hooks/useGetCategoryArticles/use-get-category-articles';
import type { ICategoryArticlesPanel } from '~/contentful/types';

export const CategoryArticlesPanel = ({ fields, sys }: ICategoryArticlesPanel) => {
  const { pageTitle } = fields;

  const { data, isLoading } = useGetCategoryArticles({
    variables: {
      // category here is a reference and we could get an archived/draft category (thats's why we use defensive chaining)
      slug: fields?.category?.fields?.slug,
    },
    enabled: !!fields?.category?.fields?.slug,
  });

  const articles = data?.articles?.results ?? [];

  if (!isLoading && articles.length < 1) {
    return null;
  }

  return (
    <ArticleCarousel
      articles={articles}
      title={pageTitle}
      listName={pageTitle ?? `Contentful - CategoryArticlesPanel: ${sys.id}`}
      isLoading={isLoading}
    />
  );
};
