import React from 'react';
import type { IComponentRichText } from '../../types';
import { RichTextComponent } from './richtext.component';
import { cva } from 'class-variance-authority';
import { cn } from '@aph/ui/tailwind/cn';

const variations = cva([], {
  variants: {
    color: {
      White: ['bg-elevated'],
      Green: ['bg-visual'],
    },
  },
});

export const RichTextSimpleComponent = ({ fields }: IComponentRichText) => {
  const { content, color } = fields;

  const isCardVariant = Boolean(color);

  return (
    <div
      className={cn(variations({ color, className: [{ 'px-2 py-3 rounded-2xl': isCardVariant }] }))}
    >
      <RichTextComponent field={content} />
    </div>
  );
};
