import NextImage from 'next/image';
import NextLink from 'next/link';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FallbackImage from '@aph/images/fallback-image.png';
import { Typography } from '@aph/ui/components/typography/typography';
import type { ContentfulImageProps } from '../next-contentful-image/next-contentful-image';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

export type EditorialCardIem = {
  image?: ContentfulImageProps['src'] | null | undefined;
  category: string;
  title: string;
  href: string;
};

type EditorialCardProps = {
  item: EditorialCardIem;
  onClick?: (item: EditorialCardIem) => void;
};

export const EditorialCard = ({ item, onClick }: EditorialCardProps) => {
  const { breakpoints } = useTheme();

  return (
    <div className="bg-elevated flex h-[175px] flex-col overflow-hidden rounded-xl md:h-[236px]">
      <NextLink href={item.href} onClick={() => onClick?.(item)} className="h-full no-underline">
        <div className="relative h-2/5 md:h-3/5">
          {item.image ? (
            <ContentfulImage
              src={item.image}
              alt={item.title}
              fill
              sizes={`
                (min-width:${breakpoints.values.md}px) 25vw, 
                (min-width:${breakpoints.values.sm}px) 33vw, 
                50vw`}
              style={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <NextImage
              src={FallbackImage}
              alt={item.title}
              fill
              style={{
                objectFit: 'cover',
              }}
            />
          )}
        </div>

        <div className="px-1.5 pb-3 pt-1 md:px-2 md:pb-1.5">
          <Typography typography="subHeading" color="text-subtle">
            {item.category}
          </Typography>
          <Typography typography="body" color="text-default" className="line-clamp-3 font-bold">
            {item.title}
          </Typography>
        </div>
      </NextLink>
    </div>
  );
};

export const EditorialCardSkeleton = () => {
  return (
    <div className="bg-elevated flex h-[201px] flex-col overflow-hidden rounded-xl sm:h-[236px]">
      <Skeleton variant="rectangular" height="60%" />
      <Skeleton variant="text" height={36} />
      <Skeleton variant="text" height={36} />
    </div>
  );
};
