import { ArticlePanel } from '~/articles/components/article-panel/article-panel';
import type { IPsidArticlePanel } from '~/contentful/types';

export const PsidArticlePanel = ({ fields, sys }: IPsidArticlePanel) => {
  const { psIds, pageTitle } = fields;

  return (
    <ArticlePanel
      articleCodes={psIds}
      title={pageTitle}
      listName={pageTitle ?? `Contentful - PsidArticlePanel: ${sys.id}`}
    />
  );
};
