import { colorTokens } from '@aph/ui/design-tokens/color-tokens';

export type ContentCardColor = 'yellow' | 'pink' | 'green';

const AVAILABLE_COLORS: Record<
  ContentCardColor,
  {
    primary: string;
    secondary: string;
  }
> = {
  yellow: {
    primary: '#FFEEBD',
    secondary: '#FFE17F',
  },
  pink: {
    primary: '#FDE8E3',
    secondary: '#FBD6CB',
  },
  green: {
    primary: colorTokens['color/background/visual'],
    secondary: colorTokens['color/background/visual-dark'],
  },
};

export const getPrimaryColor = (color: ContentCardColor) => AVAILABLE_COLORS[color].primary;
export const getSecondaryColor = (color: ContentCardColor) => AVAILABLE_COLORS[color].secondary;

const INDEX_COLORS: ContentCardColor[] = ['yellow', 'pink', 'green', 'pink'];

export const getContentCardColor = (index: number) => {
  const currentIndexColor = INDEX_COLORS[index];
  return currentIndexColor || 'yellow';
};
