import type { ArticleGroup } from '../../generated/ArticlesClient';
import {
  type UseGetPageArticleGroupsProps,
  useGetPageArticleGroups,
} from '../../hooks/use-get-page-article-groups/use-get-page-article-groups';
import { ArticleCarousel } from '../article-carousel/article-carousel';

type PageArticlePanelProps = {
  find: (predicate: ArticleGroup) => boolean;
} & Pick<UseGetPageArticleGroupsProps, 'pageId'>;

/**
 * Renders a panel for displaying articles for a specific page (e.g. pageId='start-page')
 *
 */
export const PageArticlePanel = ({ pageId, find: findArticleGroup }: PageArticlePanelProps) => {
  const { isLoading, data = [] } = useGetPageArticleGroups({ pageId });

  const { title = '', articles = [] } = data.find(findArticleGroup) ?? {};
  return <ArticleCarousel title={title} articles={articles} isLoading={isLoading} />;
};
