import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Typography } from '@aph/ui/components/typography/typography';
import type { IImage } from '../../types';
import {
  ContentfulImage,
  type ContentfulImageProps,
} from '../next-contentful-image/next-contentful-image';

type ImageProps = IImage & Pick<ContentfulImageProps, 'sizes'>;

export const Image = ({ fields, sizes = '100vw' }: ImageProps) => {
  const { caption, image, altText, link } = fields;

  const imageProps = {
    src: image.fields.file.url,
    alt: altText || image.fields.description,
    height: image.fields.file.details.image?.height,
    width: image.fields.file.details.image?.width,
    sizes,
    style: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  } satisfies ContentfulImageProps;

  return (
    <figure className="flex flex-col">
      <FeatureToggledNextLink href={link} className="group outline-none">
        <ContentfulImage
          className="ring-focus overflow-hidden rounded-xl group-focus:ring-2 sm:rounded-2xl"
          {...imageProps}
        />
      </FeatureToggledNextLink>

      {caption && (
        <Typography
          data-testid="ImageCaption"
          typography="subHeading"
          color="text-subtle"
          className="mt-1.5"
          asChild
        >
          <figcaption>{caption}</figcaption>
        </Typography>
      )}
    </figure>
  );
};
