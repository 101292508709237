import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';

const GRIDS = {
  sm: 2,
  md: 3,
  lg: 4,
  xl: 6,
};

export const useCardAmount = () => {
  const theme = useTheme();
  const aboveXl = useMediaQuery(theme.breakpoints.up('xl'));
  const aboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  const aboveSm = useMediaQuery(theme.breakpoints.up('sm'));

  if (aboveXl) {
    return GRIDS.xl;
  }
  if (aboveLg) {
    return GRIDS.lg;
  }
  if (aboveSm) {
    return GRIDS.md;
  }
  return GRIDS.sm;
};
