const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

const YOUTUBE_NO_COOKIE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?youtube-nocookie\.com\/(?:embed\/)?([a-zA-Z0-9_-]{11})/;

export const getNoCookieYouTubeUrl = (url: string) => {
  const isYouTubeNoCookieURL = YOUTUBE_NO_COOKIE_REGEX.test(url);
  if (isYouTubeNoCookieURL) {
    return url;
  }

  const match = url.match(YOUTUBE_REGEX);
  if (!match || !match[1]) {
    return null;
  }

  const videoId = match[1];
  return `https://www.youtube-nocookie.com/embed/${videoId}`;
};
