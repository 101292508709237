import { useGetArticlesByCodesQuery } from '../../hooks/queries/use-get-articles-by-code.query';
import type { ArticleCarouselProps } from '../article-carousel/article-carousel';
import { ArticleCarousel } from '../article-carousel/article-carousel';

type ArticlePanelProps = {
  articleCodes: Array<string>;
  title?: string;
} & Pick<ArticleCarouselProps, 'listName'>;

/**
 * Renders a panel for displaying articles by specifying their article codes
 *
 */
export const ArticlePanel = ({ articleCodes, ...props }: ArticlePanelProps) => {
  const { isLoading, data } = useGetArticlesByCodesQuery({
    variables: {
      codes: articleCodes,
    },
  });

  return <ArticleCarousel articles={data} isLoading={isLoading} {...props} />;
};
