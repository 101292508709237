import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChatEventTopic } from '@aph/components/common/chat/chat-event-topic';
import {
  useChatActions,
  useChatStore,
} from '@aph/components/common/chat/chat-store/use-chat-store';
import { useChatMediator } from '@aph/components/common/chat/use-chat-mediator';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import { RichTextComponent } from '~/contentful/components/rich-text/richtext.component';
import type { ICustomerServiceContactUsCard } from '~/contentful/types';

export const ContactUsCard = ({ fields }: ICustomerServiceContactUsCard) => {
  const { title, description, body, ctaText, cta } = fields;
  const [isClicked, setIsClicked] = useState(false);

  const onHandleClick = () => {
    if (!isClicked) setIsClicked(true);
  };

  const { notify } = useChatMediator();
  const [isMaximized] = useChatStore((state) => [state.chatState.isMaximized]);
  const { setIsMaximized } = useChatActions();

  const handleOpenChat = () => {
    if (isMaximized) {
      return;
    }
    setIsMaximized(true);
    notify(ChatEventTopic.Play);
  };

  return (
    <div className="bg-elevated rounded-lg px-1.5 py-2">
      <div className="flex flex-col gap-1.5">
        <Typography typography="headingMedium" asChild>
          <h2>{title}</h2>
        </Typography>
        <Typography>{description}</Typography>
      </div>
      <hr className="border-default my-1.5" />
      <RichTextComponent field={body} />
      <div className="mt-2 w-[160px]">
        {
          {
            chat: (
              <Button data-pw="contact-us-card-chat-button" onClick={handleOpenChat} width="full">
                {ctaText}
              </Button>
            ),
            phone: (
              <>
                <Button
                  className="mt-2 hidden sm:block"
                  data-pw="contact-us-card-phone-button"
                  onClick={onHandleClick}
                  width="full"
                >
                  <div className="hidden sm:block">{isClicked ? '0771-405 405' : ctaText}</div>
                </Button>
                <Button
                  className="mt-2 sm:hidden"
                  data-pw="contact-us-card-phone-button"
                  width="full"
                  asChild
                >
                  <a aria-label="Telefonnummer" href="tel:+46771405405">
                    <FormattedMessage id="CUSTOMER_SERVICE.CONTACT_US.CARD_PHONE.CALL_CTA.MOBILE" />
                  </a>
                </Button>
              </>
            ),
            mail: (
              <Button data-pw="contact-us-card-mail-button" width="full" asChild>
                <a aria-label="E-postadress" href="mailto:kundservice@apotekhjartat.se">
                  {ctaText}
                </a>
              </Button>
            ),
          }[cta]
        }
      </div>
    </div>
  );
};
