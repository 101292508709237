import { AphAccordion } from '@aph/components/common';
import type { IComponentAccordion } from '~/contentful/types';
import { RichTextComponent } from '../rich-text/richtext.component';

export const Accordion = ({ fields: { title, body }, sys: { id } }: IComponentAccordion) => (
  <AphAccordion
    key={id}
    id={id}
    accordionDetails={<RichTextComponent field={body} />}
    accordionSummary={title}
  />
);
