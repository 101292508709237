import Autoplay from 'embla-carousel-autoplay';
import Fade from 'embla-carousel-fade';
import {
  Carousel,
  CarouselContent,
  CarouselDotPagination,
  CarouselItem,
  CarouselNext,
  CarouselPagination,
  CarouselPrevious,
} from '@aph/ui/components/carousel/carousel.component';
import type { IImageLink } from '../../types';
import { ImageLink } from '../image-link/image-link';

type BannerProps = {
  imageLinks: IImageLink[];
  slideIntervalInSeconds: number;
  onClick: (image: IImageLink, index: number) => void;
  onChange: (image: IImageLink, index: number) => void;
};

export const Banner = ({ imageLinks, slideIntervalInSeconds, onClick, onChange }: BannerProps) => {
  return (
    <div className="relative flex flex-col">
      <Carousel
        className="contents"
        plugins={[
          Autoplay({
            delay: slideIntervalInSeconds * 1000,
            stopOnMouseEnter: true,
            stopOnInteraction: false,
          }),
          Fade(),
        ]}
        options={{
          loop: true,
          containScroll: false,
          slidesToScroll: 1,
          active: imageLinks.length > 1,
        }}
      >
        <CarouselPagination className="sm:[--Icon-size:20px]'' absolute bottom-0.5 right-0.5 z-10 order-2 gap-0.5 [--ButtonCircle-size:24px] [--Icon-size:16px] sm:right-1 sm:gap-1 sm:[--ButtonCircle-size:32px]">
          <CarouselPrevious size="dynamic" />
          <CarouselDotPagination className="sr-only" />
          <CarouselNext size="dynamic" />
        </CarouselPagination>
        <CarouselContent className="touch-pan-y touch-pinch-zoom">
          {imageLinks.map((image, index) => {
            return (
              <CarouselItem key={image.sys.id}>
                <ImageLink
                  href={image.fields.link}
                  src={image.fields.image.fields.file.url}
                  alt={image.fields.image.fields.description || image.fields.title}
                  height={image.fields.image.fields.file.details.image?.height}
                  width={image.fields.image.fields.file.details.image?.width}
                  sizes="100vw"
                  priority={index === 0}
                  onClick={() => onClick(image, index)}
                  onInView={() => onChange(image, index)}
                  inViewThreshold={0}
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
