import { Skeleton } from '@mui/material';
import {
  Carousel,
  CarouselContent,
  CarouselDotPagination,
  CarouselItem,
  CarouselNext,
  CarouselPagination,
  CarouselPrevious,
} from '@aph/ui/components/carousel/carousel.component';
import { Typography } from '@aph/ui/components/typography/typography';
import { ArticleCard } from '../article-grid/article-card/article-card';
import { ArticleGrid, type ArticleGridProps } from '../article-grid/article-grid';
import { useCardAmount } from './use-card-amount';

type ArticleCarouselLoading = {
  isLoading: boolean;
};

type ArticleCarouselLoaded = {
  title?: string;
  articles: ArticleGridProps['articles'];
};

type Props = ArticleCarouselLoading | ArticleCarouselLoaded;

export type ArticleCarouselProps = Props & {
  listName?: string;
};

const isLoading = (props: Props): props is ArticleCarouselLoading => {
  return 'isLoading' in props && props.isLoading;
};

export const ArticleCarousel = (props: ArticleCarouselProps) => {
  const amountToShow = useCardAmount();

  if (isLoading(props)) {
    return (
      <div data-testid="ArticleCarouselLoader" className="flex flex-col gap-3">
        <Skeleton component="h2" width="150px" />
        <ArticleGrid totalCount={amountToShow} isLoadingNext />
      </div>
    );
  }

  const { listName, articles, title } = props;

  return (
    <div data-testid="ArticleCarousel" className="flex flex-col gap-3">
      {title ? (
        <Typography typography="headingMedium" color="text-brand" asChild>
          <h2>{title}</h2>
        </Typography>
      ) : null}
      <Carousel className="contents" aria-label="Artiklelkarusell" options={{ skipSnaps: true }}>
        <CarouselPagination className="order-2">
          <CarouselPrevious />
          <CarouselDotPagination />
          <CarouselNext />
        </CarouselPagination>
        <CarouselContent className="-ml-1 touch-pan-y touch-pinch-zoom sm:-ml-2">
          {articles?.map((article, index) => (
            <CarouselItem
              key={article.articleCode}
              className="basis-1/2 pl-1 sm:basis-1/3 sm:pl-2 lg:basis-1/4 xl:basis-1/6"
              aria-label={article.name}
              aria-roledescription="Artikelkort"
            >
              <ArticleCard article={article} index={index} listName={listName || title || ''} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
