import { Grid, Stack } from '@mui/material';
import { Typography } from '@aph/ui/components/typography/typography';
import type { IBrandPage } from '~/contentful/types';
import { BrandCard } from './brand-card';

interface BrandListProps {
  title?: string;
  brands: IBrandPage[];
}

export const BrandList = ({ title, brands }: BrandListProps) => {
  return (
    <Stack rowGap={3}>
      {title ? (
        <Typography data-pw="brand-list-title" typography="headingMedium" asChild>
          <h2>{title}</h2>
        </Typography>
      ) : null}

      <Grid container spacing={2} gap={{ xs: 0, lg: 0 }}>
        {brands.map((brand) => (
          <Grid key={brand.sys.id} item xs={12} sm={6}>
            <BrandCard brand={brand} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
