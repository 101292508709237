import { useCallback } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import find from 'lodash/find';
import { createQuery } from 'react-query-kit';
import { notEmpty } from '@aph/utilities/not-empty';
import type { GetPageArticleGroupsParams } from '~/articles/api/ArticlesApiClient';
import { ArticlesApiClient } from '~/articles/api/ArticlesApiClient';
import type {
  FilterOptionReference,
  IArticle,
  IPageArticleGroupsRequest,
  PageArticleGroup,
} from '../../generated/ArticlesClient';

export type UseGetPageArticleGroupsProps = {
  pageId?: string;
  article?: IArticle;
  data?: IPageArticleGroupsRequest;
  filter?: (group: PageArticleGroup) => boolean;
};

export const useGetPageArticleGroupsQuery = createQuery({
  queryKey: ['PageArticleGroups'],
  fetcher: (variables: GetPageArticleGroupsParams, { meta }) => {
    const articlesApiClient = new ArticlesApiClient({ headers: meta?.headers });
    return articlesApiClient.getPageArticleGroups(variables);
  },
  gcTime: 0,
  staleTime: Infinity,
});

export const useIsFetchingPageArticleGroups = (pageId: string) => {
  const isFetching = useIsFetching({
    queryKey: useGetPageArticleGroupsQuery.getKey({ pageId }),
    exact: false,
  });
  return isFetching > 0;
};

export const useGetPageArticleGroups = ({
  pageId = '',
  article,
  data,
  filter,
}: UseGetPageArticleGroupsProps) => {
  let page = pageId;
  let filters: FilterOptionReference[] | undefined;

  if (article) {
    const request = find(article?.commerceRecommendationRequests, {
      relativePath: 'page-article-groups',
    });
    filters = request?.filterOptions;
    page = request?.absolutePath || pageId;
  }

  return useGetPageArticleGroupsQuery({
    variables: { pageId: page, filter: filters, data },
    enabled: notEmpty(pageId),
    select: useCallback(
      ({ articleGroups = [] }: PageArticleGroup) =>
        filter ? articleGroups.filter(filter) : articleGroups,
      [filter],
    ),
  });
};
