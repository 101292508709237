import React, { createContext, useMemo } from 'react';
import type { PageContentProps } from '~/contentful/components/content-renderer/content-renderer';

type ContentRendererContextType = {
  ContentRenderer: React.FC<PageContentProps>;
  mappings?: PageContentProps['mappings'];
};

const ContentRendererContext = createContext<ContentRendererContextType>({
  ContentRenderer: () => null,
});

const ContentRendererProvider: React.FC<{
  children: React.ReactNode;
  ContentRenderer: ContentRendererContextType['ContentRenderer'];
  mappings?: ContentRendererContextType['mappings'];
}> = ({ children, ContentRenderer, mappings }) => {
  const value = useMemo(
    () => ({
      ContentRenderer,
      mappings,
    }),
    [ContentRenderer, mappings],
  );

  return (
    <ContentRendererContext.Provider value={value}>{children}</ContentRendererContext.Provider>
  );
};

const useContentRenderer = () => {
  const context = React.useContext(ContentRendererContext);
  if (context === undefined) {
    throw new Error('useContentRenderer must be used within a ContentRendererProvider');
  }
  return context;
};

export { ContentRendererProvider, useContentRenderer };
