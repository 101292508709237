import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Typography } from '@aph/ui/components/typography/typography';
import { screens } from '@aph/ui/tailwind/screens';
import { urlify } from '@aph/utilities/slug';
import type {
  IBrandPage,
  ICategoryPage,
  IGenericPage,
  IImage,
  INavigation,
  ITipsAndAdvicePage,
} from '~/contentful/types';
import { type UnknownContentType, isContentPage } from '~/contentful/utilities/is-content-type';
import { BrandList } from '../brand-list/brand-list.component';
import { ContentCardListing } from '../content-card-listing/content-card-listing';
import { EditorialListing } from '../editorial-listing/editorial-listing';
import { Image } from '../image/image';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

const isTipsAndAdvicePageLinks = (
  pages: Array<UnknownContentType>,
): pages is Array<ITipsAndAdvicePage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'tipsAndAdvicePage');
};

const isBrandPageLinks = (pages: Array<UnknownContentType>): pages is Array<IBrandPage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'brandPage');
};

const isContentPageLinks = (pages: Array<UnknownContentType>): pages is Array<IGenericPage> => {
  return pages?.every(isContentPage);
};

const isImageLinks = (pages: Array<UnknownContentType>): pages is Array<IImage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'image');
};

const isCategoryPageLinks = (pages: Array<UnknownContentType>): pages is Array<ICategoryPage> => {
  return pages?.every((page) => page.sys.contentType.sys.id === 'categoryPage');
};

export const NavigationListing = ({ fields }: INavigation) => {
  const { pages, heading, variant } = fields;

  const isContentCardVariant = variant === 'Content Card';

  if (pages) {
    if (isTipsAndAdvicePageLinks(pages)) {
      return (
        <EditorialListing
          title={heading}
          items={pages?.map((page) => ({
            id: page.sys.id,
            title: page.fields.title,
            image: page.fields.listingImage ?? page.fields.image,
            category: page.fields.parent.fields.title,
            href: urlify(page.fields.slug),
          }))}
        />
      );
    }

    if (isBrandPageLinks(pages)) {
      return <BrandList title={heading} brands={pages} />;
    }

    if (isContentPageLinks(pages)) {
      if (isContentCardVariant) {
        return (
          <ContentCardListing
            title={heading}
            items={pages?.map((page) => ({
              title: page.fields.navigationTitle ?? page.fields.title,
              image: page.fields.navigationImage,
              description: page.fields.navigationDescription,
              href: urlify(page.fields.slug),
            }))}
          />
        );
      }
    }

    if (isImageLinks(pages)) {
      return (
        <div className="flex flex-col gap-y-2">
          {heading && (
            <Typography typography="headingMedium" asChild>
              <h2>{heading}</h2>
            </Typography>
          )}
          <div className="grid grid-cols-2 gap-x-1 gap-y-2 empty:hidden sm:grid-cols-3">
            {pages.map((image) => (
              <Image {...image} key={image.sys.id} sizes={`(min-width:${screens.sm}) 33vw, 50vw`} />
            ))}
          </div>
        </div>
      );
    }

    if (isCategoryPageLinks(pages)) {
      return (
        <div className="grid grid-cols-1 gap-2 empty:hidden sm:grid-cols-2">
          {pages.map((page) => (
            <FeatureToggledNextLink
              href={page.fields.slug}
              className="group no-underline outline-none"
              key={page.sys.id}
            >
              <div className="bg-elevated group:hover:shadow-md ring-focus flex items-center rounded-md p-1.5 group-focus:ring-2">
                <div className="relative h-[56px] w-[84px]">
                  {page.fields.navigationImage ? (
                    <ContentfulImage
                      src={page.fields.navigationImage.fields.file.url}
                      style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      alt={page.fields.navigationImage.fields.description ?? ''}
                      width={100}
                      height={100}
                    />
                  ) : null}
                </div>

                <Typography typography="headingSmall" className="ml-2">
                  {page.fields.menuTitle ?? page.fields.title}
                </Typography>
              </div>
            </FeatureToggledNextLink>
          ))}
        </div>
      );
    }

    return null;
  }

  // for now
  return null;
};
