import { createQuery } from 'react-query-kit';
import { ArticlesApiClient } from '../../api/ArticlesApiClient';

type UseGetArticlesVariables = {
  slug: string;
};

export const useGetCategoryArticles = createQuery({
  queryKey: ['get-category-articles'],
  fetcher: (variables: UseGetArticlesVariables, { meta }) => {
    const articlesApiClient = new ArticlesApiClient({ headers: meta?.headers });

    return articlesApiClient.getArticlesBySlug(variables.slug, {
      take: 8,
      byResourceType: 'category',
      sortBy: 'popular',
      ...variables,
    });
  },
  staleTime: Infinity,
});
