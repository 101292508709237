import NextLink from 'next/link';
import { useTheme } from '@mui/material/styles';
import { sendEvent } from '@aph/components/gtm/useGtm';
import { Typography } from '@aph/ui/components/typography/typography';
import { urlify } from '@aph/utilities/slug';
import type { IBrandPage } from '~/contentful/types';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

interface BrandCardProps {
  brand: IBrandPage;
}

export const BrandCard = ({ brand }: BrandCardProps) => {
  const theme = useTheme();

  const { logo, slug, pageTitle } = brand.fields;

  const trackBrandLogoClick = () => {
    sendEvent({
      event: 'brand_logolink_click',
      click_text: pageTitle,
    });
  };

  return (
    <NextLink
      data-pw="brand-card"
      onClick={() => trackBrandLogoClick()}
      href={urlify(slug)}
      className="no-underline"
    >
      <div className="bg-elevated flex items-center rounded-md p-1.5 hover:shadow-md">
        <div className="relative h-[56px] w-[84px]">
          {logo ? (
            <ContentfulImage
              src={logo}
              data-pw="brand-card-logo"
              alt={logo.fields.description ?? pageTitle}
              sizes={`(min-width:${theme.breakpoints.values.md}px) 50vw, 100vw`}
              fill
              style={{ objectFit: 'contain' }}
            />
          ) : null}
        </div>

        <Typography data-pw="brand-card-title" typography="headingSmall" className="ml-2">
          {pageTitle}
        </Typography>
      </div>
    </NextLink>
  );
};
